import * as Sentry from '@sentry/browser'

import debug from 'airship/modules/debug'

export interface Extra {
  [key: string]: unknown
}

export interface Tags {
  [key: string]: string
}

export interface Options {
  extra?: Extra
  tags?: Tags
  level?: Sentry.SeverityLevel
}

/**
 * Report an error to Sentry
 * @param err The error to report (ideally an Error object to provide a stacktrace)
 * @param options.extra (optional) Extra context
 * @param options.tags (optional) Tags to set on the report
 * @param options.level (optional) Severity level
 */
export default function reportError(err: unknown, options?: Options): void {
  Sentry.withScope((scope) => {
    if (options?.extra) {
      scope.setContext('data', options.extra)
    }

    if (options?.level) {
      scope.setLevel(options.level)
    }

    if (options?.tags) {
      scope.setTags(options.tags)
    }

    Sentry.captureException(err)

    if (debug()) {
      // eslint-disable-next-line no-console
      console.debug('[ERROR REPORTED]', err, options)
    }
  })
}
