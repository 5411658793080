import * as Sentry from '@sentry/browser'

import getPageContext from 'airship/modules/page-context'

import {rootDir} from '../../../'

let sentryData: null | SentryData = null

interface SentryData {
  DSN: string
  SENTRY_ENVIRONMENT_TAG: string
  RELEASE: string
  TAGS: {admin: boolean}
  USER?: {id: number; email: string; username: string}
}

export interface PageContext {
  context_processors: {
    sentry_data: SentryData
  }
}

try {
  const context = getPageContext<PageContext>()
  sentryData = context.context_processors.sentry_data
} catch (e) {
  // no sentry_data present, just leave sentryData null
}

if (sentryData) {
  const dsn = sentryData.DSN
  const environment = sentryData.SENTRY_ENVIRONMENT_TAG
  const release = sentryData.RELEASE
  const allowUrls = [
    /.*?\.urbanairship\.com/i,
    /storage\.googleapis\.com\/ua-airship-assets\/.*/i,
    /s3\.amazonaws\.com\/com\.urbanairship\.media\/.*/i,
  ]

  Sentry.init({
    dsn,
    allowUrls,
    environment,
    release,
    integrations: [Sentry.rewriteFramesIntegration({root: rootDir})],
    normalizeDepth: 4,
  })

  // If an event has an Error, add the Error's properties as event context.
  // Useful for custom error types that define their own properties.
  Sentry.addEventProcessor((event, hint) => {
    if (hint && hint.originalException instanceof Error) {
      event.contexts = {
        ...event.contexts,
        errorProperties: {...hint.originalException},
      }
    }
    return event
  })

  if (sentryData) {
    Sentry.setTags(stringifyValues(sentryData.TAGS))

    if (sentryData.USER) {
      Sentry.setUser(stringifyValues(sentryData.USER))
    }
  }
}

function stringifyValues(obj: {[key: string]: number | boolean | string}): {
  [key: string]: string
} {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value.toString()])
  )
}
