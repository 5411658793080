import reportError from 'airship/modules/report-error'

import {errorToIssues, ResolveError} from './errors'
import type {Issue, Output, Resolver, Shape, StandardEnum} from './types'

function getType(data: unknown): string {
  return data === null ? 'null' : typeof data
}

// Primitive resolvers

export const resolveString: Resolver<string> = (data) => {
  if (typeof data !== 'string') {
    throw new ResolveError([
      {
        message: `Expected string, got ${getType(data)}`,
        location: '',
        value: data,
      },
    ])
  }
  return data
}

export const resolveNumber: Resolver<number> = (data) => {
  if (typeof data !== 'number') {
    throw new ResolveError([
      {
        message: `Expected number, got ${getType(data)}`,
        location: '',
        value: data,
      },
    ])
  }
  return data
}

export const resolveBoolean: Resolver<boolean> = (data) => {
  if (typeof data !== 'boolean') {
    throw new ResolveError([
      {
        message: `Expected boolean, got ${getType(data)}`,
        location: '',
        value: data,
      },
    ])
  }
  return data
}

// Resolver that only expects undefined. For properties that can be undefined
// or something else, see optionalResolver below.
export const resolveUndefined: Resolver<undefined> = (data) => {
  if (data !== undefined) {
    throw new ResolveError([
      {
        message: `Expected undefined, got ${getType(data)}`,
        location: '',
        value: data,
      },
    ])
  }
  return data
}

// Advanced resolvers

export function objectResolver<S extends Shape>(shape: S): Resolver<Output<S>> {
  return (data) => {
    if (!(data instanceof Object) || Array.isArray(data)) {
      throw new ResolveError([
        {
          message: `Expected object, got ${getType(data)}`,
          location: '',
          value: data,
        },
      ])
    }

    const dataAsObj = data as Record<keyof S, unknown>
    const output: Partial<Output<S>> = {}
    const issues: Issue[] = []

    Object.entries(shape).forEach((entry) => {
      const k: keyof S = entry[0]
      const resolver = entry[1]
      try {
        output[k] = resolver(dataAsObj[k]) as Output<S>[keyof S]
      } catch (e) {
        const err = e instanceof Error ? e : new Error('Unknown resolve issue')
        issues.push(...errorToIssues(err, k as string, dataAsObj[k]))
      }
    })

    if (issues.length > 0) {
      throw new ResolveError(issues)
    }
    return data as Output<S>
  }
}

export function arrayResolver<T>(resolver: Resolver<T>): Resolver<T[]> {
  return (data: unknown) => {
    if (!Array.isArray(data)) {
      throw new ResolveError([
        {
          message: `Expected array, got ${getType(data)}`,
          location: '',
          value: data,
        },
      ])
    }
    const output: T[] = []
    const issues: Issue[] = []
    data.forEach((val, i) => {
      try {
        output.push(resolver(val))
      } catch (e) {
        const err = e instanceof Error ? e : new Error('Unknown resolve issue')
        issues.push(...errorToIssues(err, i.toString(), val))
      }
    })

    if (issues.length > 0) {
      throw new ResolveError(issues)
    }
    return data as T[]
  }
}

export function tupleResolver<T extends unknown[]>(resolvers: {
  [P in keyof T]: Resolver<T[P]>
}): Resolver<T> {
  return (data: unknown) => {
    if (!Array.isArray(data)) {
      throw new ResolveError([
        {
          message: `Expected tuple, got ${getType(data)}`,
          location: '',
          value: data,
        },
      ])
    }
    if (resolvers.length !== data.length) {
      throw new ResolveError([
        {
          message: `Expected tuple of length ${resolvers.length}, got ${data.length}`,
          location: '',
          value: data,
        },
      ])
    }
    const issues: Issue[] = []
    resolvers.forEach((resolver, i) => {
      const val = data[i]
      try {
        resolver(val)
      } catch (e) {
        const err = e instanceof Error ? e : new Error('Unknown resolve issue')
        issues.push(...errorToIssues(err, i.toString(), val))
      }
    })
    if (issues.length > 0) {
      throw new ResolveError(issues)
    }
    return data as T
  }
}

export function literalResolver<const T>(
  values: ReadonlyArray<T>
): Resolver<T> {
  return (data: unknown) => {
    if (!values.includes(data as T)) {
      throw new ResolveError([
        {
          message: `Expected one of ${JSON.stringify(
            values
          )}, got ${JSON.stringify(data)}`,
          location: '',
          value: data,
        },
      ])
    }
    return data as T
  }
}

export function enumResolver<T extends StandardEnum>(
  enumeration: T
): Resolver<T[keyof T]> {
  const numericRegex = /^\d+$/
  const values = Object.entries(enumeration)
    // Numeric enums have a reverse mapping, we need to remove it
    .filter(([k]) => !numericRegex.test(k))
    .map(([, v]) => v) as T[keyof T][]
  return literalResolver(values)
}

export function unionResolver<T extends Resolver<unknown>[]>(
  resolvers: T
): Resolver<ReturnType<T[number]>> {
  return (data: unknown) => {
    for (const resolver of resolvers) {
      try {
        return resolver(data) as ReturnType<T[number]>
      } catch {}
    }
    throw new ResolveError([
      {
        message: 'Expected one of the union types',
        location: '',
        value: data,
      },
    ])
  }
}

export function intersectionResolver<T, U>(
  resolver1: Resolver<T>,
  resolver2: Resolver<U>
): Resolver<T & U> {
  return (data: unknown) => {
    const issues: Issue[] = []
    let output: Partial<T & U> = {}
    for (const resolver of [resolver1, resolver2]) {
      try {
        output = {...output, ...resolver(data)}
      } catch (e) {
        const err = e instanceof Error ? e : new Error('Unknown resolve issue')
        issues.push(...errorToIssues(err, '', data))
      }
    }

    if (issues.length > 0) {
      throw new ResolveError(issues)
    }
    return data as T & U
  }
}

export function recursiveResolver<T>(
  resolverCallback: (self: Resolver<T>) => Resolver<T>
): Resolver<T> {
  return (data: unknown) => {
    const resolver = resolverCallback(recursiveResolver(resolverCallback))
    return resolver(data)
  }
}

export function optionalResolver<T>(
  resolver: Resolver<T>
): Resolver<T | undefined> {
  return (data: unknown) => {
    if (data === undefined) {
      return data
    }
    return resolver(data)
  }
}

export function nullableResolver<T>(resolver: Resolver<T>): Resolver<T | null> {
  return (data: unknown) => {
    if (data === null) {
      return data
    }
    return resolver(data)
  }
}

export function stringRecordResolver<T>(
  resolver: Resolver<T>
): Resolver<Record<string, T>> {
  return (data: unknown) => {
    if (!(data instanceof Object)) {
      throw new ResolveError([
        {
          message: `Expected Object, got ${getType(data)}`,
          location: '',
          value: data,
        },
      ])
    }
    const issues: Issue[] = []
    const output: Record<string, T> = {}
    for (const [k, v] of Object.entries(data)) {
      try {
        output[k] = resolver(v)
      } catch (e) {
        const err = e instanceof Error ? e : new Error('Unknown resolve issue')
        issues.push(...errorToIssues(err, k, v))
      }
    }

    if (issues.length > 0) {
      throw new ResolveError(issues)
    }
    return data as Record<string, T>
  }
}

export function partialRecordResolver<K extends string, V>(
  keyResolver: Resolver<K>,
  valueResolver: Resolver<V>
): Resolver<Partial<Record<K, V>>> {
  return (data: unknown) => {
    if (!(data instanceof Object)) {
      throw new ResolveError([
        {
          message: `Expected Object, got ${getType(data)}`,
          location: '',
          value: data,
        },
      ])
    }
    const issues: Issue[] = []
    const output: Partial<Record<K, V>> = {}
    for (const [k, v] of Object.entries(data)) {
      let resolvedKey: K
      try {
        resolvedKey = keyResolver(k)
      } catch (e) {
        if (e instanceof ResolveError) {
          // This isn't a key we care about; skip validation on the value
          continue
        }
        throw e
      }
      try {
        output[resolvedKey] = valueResolver(v)
      } catch (e) {
        const err = e instanceof Error ? e : new Error('Unknown resolve issue')
        issues.push(...errorToIssues(err, k, v))
      }
    }

    if (issues.length > 0) {
      throw new ResolveError(issues)
    }
    return data as Partial<Record<K, V>>
  }
}

/**
 * A resolver that does no validation. Aside from client calls that don't care
 * about the returned data, avoid this when possible!
 */
export function noopResolver<T = void>(): Resolver<T> {
  return (data: unknown) => data as T
}

/**
 * A wrapper around a resolver that will report type issues to Sentry and then
 * ignore them. Useful for preventing edge cases from blowing up the page: for
 * example, if you had a resolver for "platform" but you forgot "wns" was an
 * option, when a customer using "wns" opens the page, we'll be notified of the
 * issue but the page will (probably) still load.
 */
export function warningResolver<T>(resolver: Resolver<T>): Resolver<T> {
  return (data: unknown) => {
    try {
      return resolver(data)
    } catch (err) {
      reportError(err, {
        level: 'warning',
        extra: {data},
      })
      return data as T
    }
  }
}
